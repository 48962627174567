import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ActivatedRoute } from '@angular/router';

//Services
import { CampaignService } from './services/campaign.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showEndState$: boolean | null;
  redirect_state: string;

  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
	 private route: ActivatedRoute,
    private campaignService: CampaignService) {
    angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit(){
	  this.route.paramMap.subscribe(params => {
		  this.redirect_state=params.get('id'); 
	  });
	  console.log('redirect',this.redirect_state);
    this.campaignService.checkCampaign()
    .subscribe((data) => {
      if (data.errorMessage) {
        data = JSON.parse(data.errorMessage);
      }
      if(data.status == 203) {
        this.showEndState$ = true;
      }
      else if(data.status == 400) {
        let url: string = 'https://www.bet.com/bet-awards';
		  if(this.redirect_state!=="noredirect"){
			  this.campaignService.redirectToUrl(url);
		  }
        
      }
    });
  }


}
