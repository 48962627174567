// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  	//apiUrl: 'https://lqt833xm3j.execute-api.us-east-1.amazonaws.com/production/'
	apiUrl: 'https://dhpp0cedtb.execute-api.us-east-1.amazonaws.com/prod/'
	//apiUrl: 'https://v55w2v7i95.execute-api.us-east-1.amazonaws.com/production/'
};

/*8o7c7anrwl
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
